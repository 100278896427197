// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest,
    placeUndo,
    placeRedo,
    placeBoldText,
    placeBoldLeftText,
    placeErase,
    plotFunction,
    createAxes,
    createSpace,
    drawPoint,
    writeMediumText,
    writeSmallText,
    writeLargeText,
    drawSegment
} from '../../../../common/edliy.js';
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS//////////////////////////////////
var graph = createSpace(-4, 11, -2, 13);
  graph.options.layer['image'] =12;
  graph.options.layer['circle'] =13;
  graph.options.layer['chart'] =14;
  graph.options.layer['sector'] =15;
  graph.options.layer['line'] =15;
/////////////////////////////////////////////////////////////////////
  makeResponsive(graph);
///////////////////////////////////////////////////////////
  createAxes(graph);
  placeTitle(graph, 'Function Table from Graph','Use the graph below to complete the table');
  placeLogo(graph);
  var i=1; var j=0; var x ='x'; var y ='y';
  var shuffle = placeShuffle(graph);
  var rght = placeImage(graph, '/assets/check.svg', 7.5, -1.5);
  var wrng = placeImage(graph, '/assets/cross.svg', 7.5, -1.5);
  rght.setAttribute({visible:false});
  wrng.setAttribute({visible:false});
  shuffle.setLabel('Tap to Shuffle');
  shuffle.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  shuffle.on('over', function () {this.label.setAttribute({visible:true});});
  shuffle.on('out', function () {this.label.setAttribute({visible:false});});
///////////////////////////////////////////////////
  var test =placeTest(graph);
  test.setLabel('Tap to Check Your Answer');
  test.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  test.on('over', function () {this.label.setAttribute({visible:true});});
  test.on('out', function () {this.label.setAttribute({visible:false});});
////////////////////////////////////////////////////
  shuffle.on('down', function(){
  rght.setAttribute({visible:false});
  wrng.setAttribute({visible:false});
  clearInputFields(graph);
  domain = [Math.round(1+2*Math.random()), Math.round(3+2*Math.random()), Math.round(5+2*Math.random()),
  Math.round(7+1*Math.random())];
  range = [Math.round(1+3*Math.random()), Math.round(2+2*Math.random()), Math.round(5+2*Math.random()), Math.round(6+2*Math.random())];
  k =0;
  if(i<3){i+=1;}else{i=1}
});
//////////////////////////////////////////////
var k=0;
var Points=[];
var x1 =placeInput(graph, -3.8,6.5,"");
var y2 =placeInput(graph, -1.8,7.5,"");
var y4 = placeInput(graph, -1.8,5.5,"");
var x3 = placeInput(graph, -3.8,4.5,"");
//////////////////////////////////////////////////
var array =[];
var domain=[1, 3, 5, 7];
var range = [2, 4, 6, 8];
array.push(domain);
array.push(range);
////////////////////////////////////////
var xList=[]; var yList=[];
xList[0] = domain[0];
xList[1] = x1.Value();
xList[2] = domain[2];
xList[3] = x3.Value();
/////////////////////////////////////
yList[0] = y2.Value();
yList[1] = range[1];
yList[2] = y4.Value();
yList[3] = range[3];
/////////////////////////////////////////
for(let k=0; k<4; k++){
Points[k] = drawPoint(graph, ()=>domain[k], ()=>range[k]);
Points[k].setAttribute({size:4, fixed:true, color:'blue'});
}
/*
graph.on('up',
          function (evt)
          {
          if(k<4 && !shuffle.mouseover && !test.mouseover)
          {
          Points[k] = graph.create('point', graph.getUsrCoordsOfMouse(evt), {name:'',size:4, snapToGrid:true});
          Points[k].setAttribute({fixed:true, color:'blue'});
          v[k] =drawSegment(graph, [Points[k].X(), Points[k].Y()], [Points[k].X(), 0.0]);
          v[k].setAttribute({dash:1});
          h[k] = drawSegment(graph, [Points[k].X(), Points[k].Y()], [0.0, Points[k].Y()]);
          h[k].setAttribute({dash:1});
          xList[k] = Points[k].X();
          yList[k] = Points[k].Y();
          k+=1;
          }
          graph.update();
        }); */
/////////////////////////////Cubic FUNCTION//////////////
test.on('down', function(){
  rght.setAttribute({visible:false});
  wrng.setAttribute({visible:false});
  xList[0] = domain[0];
  xList[1] = 1.*x1.Value();
  xList[2] = domain[2];
  xList[3] = 1.*x3.Value();
  //////////////////////
  yList[0] = 1.*y2.Value();
  yList[1] = range[1];
  yList[2] = 1.*y4.Value();
  yList[3] = range[3];
  /////////////////////////////
  if(JSON.stringify(xList)==JSON.stringify(domain) && JSON.stringify(yList)==JSON.stringify(range)){rght.setAttribute({visible:true})}else{wrng.setAttribute({visible:true})}});
  var xText =writeMediumText(graph, -3, 8+0.5-k, 'x');
  xText.setAttribute({color:'blue'});
  var yText =writeMediumText(graph, -1, 8+0.5-k,'y' );
  yText.setAttribute({color:'blue'});
  for(let k=0; k<4; k++){
    var aText =writeMediumText(graph, -3, 7+0.5-k, ()=>domain[k]);
    aText.setAttribute({color:'blue', visible:()=>k==0||k==2});
    var bText= writeMediumText(graph, -1, 7+0.5-k, ()=>range[k]);
    bText.setAttribute({color:'blue', visible:()=>k==1||k==3});
  }
////////////////////////////////////////////END OF OPERATIONS///////////////////////////////
//brd1.unsuspendUpdate();
},
}
export default Boxes;
